<template>
  <modal name="RequestsDetailPopup" transition="pop-out" @opened="opened" @before-open="beforeOpen">
    <div class="requestsDashboardDetail">

      <div
        v-if="justInfo !== true"
        class="requestsDashboardDetail__inner"
      >
        <div class="requestsDashboardDetail__title">Additional info<br>for the manual flight request</div>
        <div class="requestsDashboardDetail__info">
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Type way</div>
            <div class="requestsDashboardDetail__info-item-value">Round-trip</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Booking</div>
            <div class="requestsDashboardDetail__info-item-value">Charter aircraft and charter seat</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Take off</div>
            <div class="requestsDashboardDetail__info-item-value">Republic airport (KFRG)</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Land</div>
            <div class="requestsDashboardDetail__info-item-value">Republic airport (KFRG)</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">From</div>
            <div class="requestsDashboardDetail__info-item-value">11/21/2018</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">To</div>
            <div class="requestsDashboardDetail__info-item-value">11/21/2018</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Passengers</div>
            <div class="requestsDashboardDetail__info-item-value">2</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Aircraft type</div>
            <div class="requestsDashboardDetail__info-item-value">very light jet, light jet and midsize jet</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Price range</div>
            <div class="requestsDashboardDetail__info-item-value">max price $7,000</div>
          </div>
        </div>
        <!-- <div class="requestsDashboardDetail__bottom">
          <span
            v-if="type === 'current'"
            class="requestsDashboardDetail__link js-popup"
            @click="showBookingCreationForm"
          >
            New booking
          </span>
          <span
            class="requestsDashboardDetail__link requestsDashboardDetail__link--remove js-removeRequest"
            @click="showConfirmation"
          >
            Remove the request
          </span>
        </div> -->
      </div>

      <div
        v-else
        class="requestsDashboardDetail__inner"
      >
        <div class="requestsDashboardDetail__title">Additional info<br>for the manual flight request</div>
        <div class="requestsDashboardDetail__info" v-if="additionalInfo">
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Number</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.number}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">name</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.fullName}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Direction</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.direction == 1 ? 'RT' : 'OW'}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Booking type</div>
            <div class="requestsDashboardDetail__info-item-value">{{this.bookingType[additionalInfo.bookingType]}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Take off</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.departure}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Land</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.arrival}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">From</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.departureDate ? moment(additionalInfo.departureDate).format('M/D/YYYY') : ''}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">To</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.returnDate ? moment(additionalInfo.returnDate).format('M/D/YYYY') : ''}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Passengers</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.pax}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Aircraft type</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.aircraftTypeValue}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Max price</div>
            <div class="requestsDashboardDetail__info-item-value">{{additionalInfo.maxPrice}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Status</div>
            <div class="requestsDashboardDetail__info-item-value" style="text-transform: uppercase">{{additionalInfo.statusValue}}</div>
          </div>
          <div class="requestsDashboardDetail__info-item">
            <div class="requestsDashboardDetail__info-item-title">Request date</div>
            <div class="requestsDashboardDetail__info-item-value">{{moment(additionalInfo.createdOn).format('M/D/YYYY')}}</div>
          </div>
        </div>
      </div>

      <button class="fancybox-button fancybox-close-small" @click="$modal.hide('RequestsDetailPopup')"></button>
    </div>
  </modal>
</template>

<script>
  import moment from 'moment'
  import './RequestsDetailPopup.styl'

  export default {
    name: 'RequestsDetailPopup',
    props: ['justInfo'],
    data: () => ({
      type: null,
      additionalInfo: null,
      bookingType: {
        2: 'Charter Aircraft',
        4: 'Charter Aircraft Seat',
        16: 'Charter Flight',
        32: 'Charter Flight Seat',
        64: 'Charter Aircraft Or Seat',
      },
    }),
    methods: {
      moment,
      opened () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          const container = document.querySelector('.vm--container')
          document.querySelector('body').appendChild(container)
        }
      },
      beforeOpen (event) {
        this.type = event.params.type
        this.additionalInfo = event.params.additionalInfo
      },
      showConfirmation () {
        this.$modal.hide('RequestsDetailPopup')
        this.$modal.show('questionPopupFlightRequest')
      },
      showBookingCreationForm () {
        this.$modal.hide('RequestsDetailPopup')
        this.$modal.show('NewBookingPopup')
      },
    },
  }
</script>
